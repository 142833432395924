import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import './index.css'
import { store } from '../src/redux/store'
import { Provider } from 'react-redux'

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <React.StrictMode>
      <div className='all-page'>
      <Provider store={store}>
          <App />
      </Provider>
      </div>
    </React.StrictMode>
  </I18nextProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
