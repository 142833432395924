import React from 'react';
import './Client.css';
import Conqueiros from '../Assets/Logos_Clientes/torre-das-figueiras.png';
import { withTranslation } from 'react-i18next';
import Footer from '../Components/Footer/Footer';
import Comselo from '../Assets/Selos/com_selo.svg';
import Semselo from '../Assets/Selos/sem_selo.svg';

import SeloAware from '../Assets/Selos/Selos_png/selo_aware.png';
import SeloImprovement from '../Assets/Selos/Selos_png/selo_improvement.png';
import SeloNature from '../Assets/Selos/Selos_png/selo_naturepositive.png';
import SeloNeutral from '../Assets/Selos/Selos_png/selo_neutral.png';

const Client = (props) => {
    const { t, logoImg, logoText, title, measured, commited, compliant, resilient } = props;
    return (
        <>
            {/* {logoImg ? <img className='client-logo' src={logoImg} /> : <h1 style={{textAlign:"center", marginTop:"1em"}}>{title}</h1> } */}
            {logoText && <span className='conqueiros-text font-family-roboto'><span style={{fontWeight:"bold"}}>{logoText.split("adds")[0]}</span> adds {t(logoText.split("adds")[1])}</span>}
            <div className='container-stamps display-flex space-between-horizontal'>
                <div className='container-stamp display-flex flex-direction-column center-items-vertical '>
                    <img className='image' src={SeloAware} />
                    <span className='title font-family-proxima'>{t("MEASURING_TITLE")}</span>
                    <span className='subtitle font-family-roboto'>{t("MEASURING_SUBTITLE")}</span>
                    <img className='check' src={measured ? Comselo : Semselo} />
                </div>
                <div className='container-stamp display-flex flex-direction-column center-items-vertical '>
                    <img className='image' src={SeloImprovement} />
                    <span className='title font-family-proxima'>{t("IMPROVING_TITLE")}</span>
                    <span className='subtitle font-family-roboto'>{t("IMPROVING_SUBTITLE")}</span>
                    <img className='check' src={commited ? Comselo : Semselo} />
                </div>
                <div className='container-stamp display-flex flex-direction-column center-items-vertical '>
                    <img className='image' src={SeloNeutral} />
                    <span className='title font-family-proxima'>{t("COMPLIANT_TITLE")}</span>
                    <span className='subtitle font-family-roboto'>{t("COMPLIANT_SUBTITLE")}</span>
                    <img className='check' src={compliant ? Comselo : Semselo} />
                </div>
                <div className='container-stamp display-flex flex-direction-column center-items-vertical '>
                    <img className='image' src={SeloNature} />
                    <span className='title font-family-proxima'>{t("RESILIENT_TITLE")}</span>
                    <span className='subtitle font-family-roboto'>{t("RESILIENT_SUBTITLE")}</span>
                    <img className='check' src={resilient ? Comselo : Semselo} />
                </div>
            </div>
        </>
    )
}


export default withTranslation()(Client);