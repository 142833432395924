import React, { useEffect, useState } from "react";
import logo from '../../Assets/logo.svg';
import "./Navbar.css";
import { UilBars } from '@iconscout/react-unicons';
import { UilTimesCircle } from '@iconscout/react-unicons'
import { useLocation, useNavigate } from 'react-router-dom';
import logo2 from '../../Assets/logo2.svg';
import { HashLink } from "react-router-hash-link";
import { UilArrowLeft } from '@iconscout/react-unicons'
import Language from '../Language/Language'

const Navbar = (props) => {
    const {t} = props;

    const [openNavbar, setOpenNavbar] = useState(true);
    const location = useLocation();
    const [currentLogo, setCurrentLogo] = useState("");
    const navigate = useNavigate();
    const [dimensions, setDimensions] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    const handleResize = () => {
        setDimensions({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    }

    useEffect(() => {
        const navbar = document.querySelector('.navbar-container');
        const colorLinks = document.querySelectorAll('.navbar-links');
        const languageLink = document.getElementById('language');
        window.addEventListener("resize", handleResize, false);
        window.onscroll = () => {
            if (window.scrollY > 0) {
                if (window.location.pathname.split('/')[1] !== 'our-pioneers' && window.location.pathname.split('/')[1] !== 'badges') {
                    if (window.innerWidth <= 960) {
                        navbar.style.backgroundColor = "white";
                    }
                    else {
                        if (window.innerWidth > 960) {
                            for (let i = 0; i < colorLinks.length; i++) {
                                colorLinks[i].style.color = "white";
                            }
                            languageLink.style.color = "white";
                            
                        }
                        else {
                            for (let i = 0; i < colorLinks.length; i++) {
                                colorLinks[i].style.color = "black";
                            }
                            languageLink.style.color = "black";
                        }
                    }
                    setCurrentLogo("logo2");
                }
                navbar.style.backgroundColor = "white";
                navbar.classList.add('nav-active');
                for (let i = 0; i < colorLinks.length; i++) {
                    colorLinks[i].style.color = "black";
                }
                languageLink.style.color = "black";
            } else {
                if (window.location.pathname.split('/')[1] !== 'our-pioneers' && window.location.pathname.split('/')[1] !== 'badges') {
                    if (window.innerWidth <= 960) {
                        if (!openNavbar) {
                            navbar.style.backgroundColor = "white";
                            setCurrentLogo("logo2");
                        }
                        else {
                            setCurrentLogo("logo");
                            navbar.style.backgroundColor = "unset";
                        }
                    }
                    else {
                        for (let i = 0; i < colorLinks.length; i++) {
                            colorLinks[i].style.color = "white";
                        }
                        languageLink.style.color = "white";
                        navbar.style.backgroundColor = "unset";
                        setCurrentLogo("logo");
                    }
                }
                else {
                    if (window.innerWidth <= 960) {
                        navbar.style.backgroundColor = "white";
                    }
                    else {
                        navbar.style.backgroundColor = "unset";
                    }
                    for (let i = 0; i < colorLinks.length; i++) {
                        colorLinks[i].style.color = "black";
                    }
                    languageLink.style.color = "black";
                    setCurrentLogo("logo2");
                }
                navbar.classList.remove('nav-active');
            }
        };
        if (window.scrollY > 0) {
            for (let i = 0; i < colorLinks.length; i++) {
                colorLinks[i].style.color = "black";
            }
            languageLink.style.color = "black";
            setCurrentLogo("logo2");
        }
        else {
            if (window.location.pathname.split('/')[1] !== 'our-pioneers' && window.location.pathname.split('/')[1] !== 'badges') {
                if (window.innerWidth <= 960) {
                    if (!openNavbar) {
                        setCurrentLogo("logo2");
                        navbar.style.backgroundColor = "white";
                        for (let i = 0; i < colorLinks.length; i++) {
                            colorLinks[i].style.color = "black";
                        }
                        languageLink.style.color = "black";
                    }
                    else {
                        for (let i = 0; i < colorLinks.length; i++) {
                            colorLinks[i].style.color = "white";
                        }
                        languageLink.style.color = "white";
                        setCurrentLogo("logo");
                        navbar.style.backgroundColor = "unset";
                    }
                }
                else {
                    for (let i = 0; i < colorLinks.length; i++) {
                        colorLinks[i].style.color = "white";
                    }
                    languageLink.style.color = "white";
                    navbar.style.backgroundColor = "unset";
                    setCurrentLogo("logo");
                }
            }
            else {
                navbar.style.backgroundColor = "white";
                for (let i = 0; i < colorLinks.length; i++) {
                    colorLinks[i].style.color = "black";
                }
                languageLink.style.color = "black";
                setCurrentLogo("logo2");
            }
        }
    }, [window.location.pathname, dimensions.width, openNavbar, window.innerWidth]);


    const scrollWithOffset = (el, type) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        var yOffset;
        if (type === "contact") {
            yOffset = window.innerWidth <= 960 ? -100 : -60;
        }
        else if (type === "our-pioneers") {
            yOffset = window.innerWidth <= 960 ? -200 : -180;
        }
        else {
            yOffset = window.innerWidth <= 960 ? -200 : -110;
        }
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
    }


    return (
        <div style={{ display: window.location.pathname.split('/')[1] === "page-not-found" && "none" }} className={`navbar-container ${!openNavbar ? 'background-white show-index' : 'hide-navbar-container'}`}>
            {window.innerWidth <= 960 && location.pathname.split('/')[1] === 'badges' && (
                <UilArrowLeft onClick={() => { navigate(-1); !openNavbar && setOpenNavbar(!openNavbar) }} style={{ position: "absolute", top: "50px", left: "5%" }} color="black" size={30} />
            )}
            <HashLink exact to="/" onClick={e => { window.scrollTo({ top: 0, behavior: 'smooth' }); !openNavbar && setOpenNavbar(!openNavbar) }} className={"nav-logo"}>
                {currentLogo === "logo" ? <img alt="logo" src={logo} /> : <img alt="logo2" src={logo2} />}
            </HashLink>
            {
                openNavbar ? (
                    <UilBars className={location.pathname.split('/')[1] === 'our-pioneers' || location.pathname.split('/')[1] === 'badges' || window.scrollY > 0 ? "nav-icon-black" : "nav-icon"} onClick={e => setOpenNavbar(!openNavbar)} />
                ) : (
                    <UilTimesCircle className={location.pathname.split('/')[1] === 'our-pioneers' || window.scrollY >= 0 ? "nav-icon-black" : "nav-icon"} onClick={e => setOpenNavbar(!openNavbar)} />
                )
            }
            <div className={!openNavbar ? `navbar-items active ${window.innerWidth <= 960 && 'fade-in-image'}` : "navbar-items hide-navbar-items"}>
                <div className="navbar-item">
                    <HashLink smooth exact to="/" onClick={e => { window.scrollTo({ top: 0, behavior: 'smooth' }); setOpenNavbar(!openNavbar) }} className="navbar-links" style={{ color: location.pathname.split('/')[1] === 'our-pioneers' && '#434444' }}>
                        {t("HOME")}
                    </HashLink>
                </div>
                {/* <div className="navbar-item">
                    <HashLink exact to="/#about" activeClassname="active" className="navbar-links" style={{ color: location.pathname.split('/')[1] === 'our-pioneers' && '#434444' }}>
                        About us
                    </HashLink>
                </div> */}
                <div className="navbar-item">
                    <HashLink smooth scroll={el => scrollWithOffset(el)} exact to="/#about-us" onClick={e => setOpenNavbar(!openNavbar)} className="navbar-links" style={{ color: location.pathname.split('/')[1] === 'our-pioneers' && '#434444' }}>
                        {t("ABOUT_US")}
                    </HashLink>
                </div>

                <div className="navbar-item">
                    <HashLink smooth scroll={el => scrollWithOffset(el)} exact to="/#our-services" onClick={e => setOpenNavbar(!openNavbar)} className="navbar-links" style={{ color: location.pathname.split('/')[1] === 'our-pioneers' && '#434444' }}>
                        {t("OUR_SERVICES")}
                    </HashLink>
                </div>
                {/* <div className="navbar-item">
                    <HashLink smooth scroll={el => scrollWithOffset(el, "our-pioneers")} exact to="/#our-pioneers" onClick={e => setOpenNavbar(!openNavbar)} className="navbar-links" style={{ color: location.pathname.split('/')[1] === 'our-pioneers' && '#434444' }}>
                        {t("OUR_PIONEERS")}
                    </HashLink>
                </div> */}
                <div className="navbar-item">
                    <HashLink smooth scroll={el => scrollWithOffset(el, "contact")} id="contact-link" onClick={e => setOpenNavbar(!openNavbar)} exact to="/#contact" className="navbar-links" style={{ color: location.pathname.split('/')[1] === 'our-pioneers' && '#434444' }}>
                        {t("CONTACT")}
                    </HashLink>
                </div>
                <div className="navbar-item">
                    <Language />
                </div>
            </div>
        </div>
    )

}

export default Navbar;