import { withTranslation } from 'react-i18next';
import { useEffect, useState, useMemo } from 'react';
import './PopUpForm.css';
import popupimage from '../../Assets/icone-form.svg';
import legfile from '../../Assets/POLÍTICA DE PRIVACIDADE (no webite).docx';
import { UilTimesCircle } from '@iconscout/react-unicons'
import { UilUser } from '@iconscout/react-unicons'
import { UilCalendarAlt } from '@iconscout/react-unicons'
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import moment from 'moment-timezone';
import 'react-notifications/lib/notifications.css';
import { addDays, getDay } from 'date-fns'
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { useSelector, useDispatch } from 'react-redux';
import { toggleContactPopup } from '../../redux/contactPopup';
import Select from 'react-select';
import { UilEnvelopeEdit } from '@iconscout/react-unicons'
import countryList from 'react-select-country-list'
import ReactFlagsSelect from "react-flags-select";
import { UilGlobe } from '@iconscout/react-unicons'
import { UilSubject } from '@iconscout/react-unicons'

const apiBase = process.env.REACT_APP_API_BASE_URL;
const apiKey = process.env.REACT_APP_API_KEY;

const PopUpForm = (props) => {
    const excludedCountries = ['India', 'Pakistan', 'Bangladesh'];
    const { t } = props;
    const { contactPopupOpen, contactPopupMode } = useSelector((state) => state.contactPopup);
    const dispatch = useDispatch();
    const [value, setValue] = useState()
    const [state, setState] = useState({
        name: "",
        email: "",
        subject: '',
        country: ''
    })
    const [allowedHours, setAllowedHours] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [timezoneOffset, setTimezoneOffset] = useState(null);
    const options = useMemo(() => countryList().getData().filter(
        (country) => !excludedCountries.includes(country.label)
    ), [])
    const [submitingForm, setSubmitingForm] = useState(false);

    const subjectOptions = [
        { value: 'agro', label: 'AGRO' },
        { value: 'forest', label: 'FOREST' },
        { value: 'co2', label: 'CO2' },
        { value: 'other', label: t('OTHER') },
    ]

    useEffect(() => {
        let maxDate = new Date();
        let minDate = new Date();
        maxDate.setHours(18);
        maxDate.setMinutes(0);
        minDate.setHours(9);
        minDate.setMinutes(0);
        var userTimezoneOffset = maxDate.getTimezoneOffset() * 60000;
        setTimezoneOffset(userTimezoneOffset);
        maxDate = new Date(maxDate.getTime() - userTimezoneOffset);
        minDate = new Date(minDate.getTime() - userTimezoneOffset);
        setAllowedHoursMinutesBetweenDates(minDate, maxDate);
    }, [])

    const handleSubmit = async () => {
        setSubmitingForm(true);
        if(contactPopupMode == "meeting") {
            if (state.name && value && startDate && Object.keys(state.subject).length > 0 && Object.keys(state.country).length > 0) {
                try {
                    if (allowedHours.includes(`${startDate.getHours()}:${startDate.getMinutes()}`)) {
                        await fetch(`${apiBase}postMeetingSchedule/${apiKey}`, {
                            method: 'POST',
                            body: JSON.stringify({
                                name: state.name,
                                phone: value,
                                date: moment.utc(startDate).format("YYYY-MM-DD HH:mm:ss"),
                                subject: state.subject.value,
                                country: state.country.label,
                                email: null,
                                creationSource: "https://forest.agroinsider.com/"
                            }),
                            headers: {
                                'Content-Type': 'application/json'
                            }
                        });
                        NotificationManager.success(`${t("MEETING_SCHEDULED_FOR")} ${moment(startDate).format("DD/MM/YYYY")} ${t("AT")} ${moment(startDate).format("HH:mm")}h`, t('SUCCESS'), 5000);
                        dispatch(toggleContactPopup("closePopup"))
                        setSubmitingForm(false);
                        setState({
                            name: "",
                            email: "",
                            subject: '',
                            country: ''
                        })
                        setStartDate(null);
                        setValue();
                    } else {
                        NotificationManager.info(`${t("INVALID_DATE")}`, t('INFO'), 5000);
                        setSubmitingForm(false);
                    }
                } catch (e) {
                    NotificationManager.error(`${t("ERROR_SUBMITING_FORM")}`, t('ERROR'), 5000);
                    setSubmitingForm(false);
                }
            }
            else {
                NotificationManager.info(`${t("FILL_ALL_FIELDS")}`, t('INFO'), 5000);
                setSubmitingForm(false);
            }
        } else {
            if (state.name && value && startDate && Object.keys(state.subject).length > 0 && Object.keys(state.country).length > 0 && state.email) {
                try {
                   
                    if (allowedHours.includes(`${startDate.getHours()}:${startDate.getMinutes()}`)) {
                        // await fetch(`${apiBase}postMeetingSchedule/${apiKey}`, {
                        //     method: 'POST',
                        //     body: JSON.stringify({
                        //         name: state.name,
                        //         phone: value,
                        //         date: moment.utc(startDate).format("YYYY-MM-DD HH:mm:ss"),
                        //         subject: state.subject.value,
                        //         country: state.country.label,
                        //         email: state.email,
                        //     }),
                        //     headers: {
                        //         'Content-Type': 'application/json'
                        //     }
                        // });
                        NotificationManager.success(`Meeting scheduled for ${moment(startDate).format("DD/MM/YYYY")} at ${moment(startDate).format("HH:mm")}h`, 'Sucess', 5000);
                    } else {
                        NotificationManager.info('Invalid Date, choose another date', 'Info', 5000);
                    }
                    dispatch(toggleContactPopup("closePopup"))
                    setSubmitingForm(false);
                    setState({
                        name: "",
                        email: "",
                        subject: '',
                        country: ''
                    })
                    setStartDate(null);
                    setValue();
                } catch (e) {
                    NotificationManager.error('Error', 'Error submiting form', 5000);
                    setSubmitingForm(false);
                }
            }
            else {
                NotificationManager.info('Fill all fields', 'Info', 5000);
                setSubmitingForm(false);
            }
        }
    }

    const setAllowedHoursMinutesBetweenDates = (initialDate, endDate) => {
        let hoursArray = [];
        while (initialDate.getTime() <= endDate.getTime()) {
            hoursArray.push(`${initialDate.getHours()}:${initialDate.getMinutes()}`);
            initialDate = new Date(initialDate.getTime() + 30 * 60000);
        }
        setAllowedHours(hoursArray);
    }

    const filterPassedTime = (time) => {
        const selectedDate = new Date(time);
        if (startDate) {
            return allowedHours.includes(`${selectedDate.getHours()}:${selectedDate.getMinutes()}`);
        }
        else {
            return false;
        }
    };

    const isWeekday = (date) => {
        const day = getDay(date);
        if (timezoneOffset <= -21600000 || timezoneOffset >= 21600000) {
            if (timezoneOffset <= -21600000) {
                return day !== 0 && day !== 1 && day !== 6;
            }
            if (timezoneOffset >= 21600000) {
                return day !== 0 && day !== 5 && day !== 6;
            }
        }
        return day !== 0 && day !== 6;
    }

    return (
        <>
            {contactPopupOpen ?
                <div className="popup-form-container">
                    <NotificationContainer />
                    <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
                        <UilTimesCircle color="#04A953" onClick={() => dispatch(toggleContactPopup("closePopup"))} size="30" style={{ cursor: "pointer" }} />
                    </div>
                    <img alt='popupimage' className="form-logo" src={popupimage} />
                    <span className='popup-form-title font-family-proxima'>{t("WANT_TO_SCHEDULE_A_CALL")}</span>
                    <span className='popup-form-subtitle font-family-roboto'>{t("CALL_DETAILS")}</span>
                    <div style={{ position: "relative", width: "100%" }}>
                        <UilUser className="inputs-icons" color="#04A953" size="30" />
                        <input className='popup-form-input' onChange={e => setState(prev => ({ ...prev, name: e.target.value }))} value={state.name} placeholder={t("NAME")} />
                    </div>
                    {contactPopupMode != "meeting" &&
                        (<div style={{ position: "relative", width: "100%" }}>
                            <UilEnvelopeEdit color="#04A953" size="30" className="inputs-icons" />
                            <input className='popup-form-input' onChange={e => setState(prev => ({ ...prev, email: e.target.value }))} value={state.email} placeholder={t("EMAIL")} />
                        </div>)
                    }
                    <div style={{ position: "relative", marginTop: "0.5em", width: "100%" }}>
                        <UilSubject color="#04A953" size="30" style={{top:"5px"}} className="inputs-icons" />
                        <Select value={state.subject} placeholder={t("SUBJECT")} onChange={e => setState(prev => ({ ...prev, subject: e}))} required options={subjectOptions} />
                    </div>
                    <div style={{ position: "relative", marginTop: "0.5em", width: "100%" }}>
                        <UilGlobe color="#04A953" size="30" style={{top:"5px"}} className="inputs-icons" />
                        <Select value={state.country} placeholder={t("COUNTRY")} options={options} required onChange={e => setState(prev => ({ ...prev, country: e}))} />
                    </div>
                    <div style={{ position: "relative", width: "100%" }}>
                        <div style={{ borderRadius: "0.5em", border: "2px solid #8A8A8A", padding: "0.5em 1em 0.5em 4em", marginTop: "0.5em", width: "76%" }}>
                            <PhoneInput
                                international
                                defaultCountry="PT"
                                placeholder="Enter phone number"
                                value={value}
                                onChange={setValue} />
                        </div>
                    </div>
                    <div style={{ position: "relative", width: "100%" }}>
                        <UilCalendarAlt color="#04A953" size="30" style={{ position: "absolute", zIndex: "1", top: "15px", left: "20px" }} />
                        {allowedHours && <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            showTimeSelect
                            minDate={addDays(moment(moment(new Date()).utc().format("YYYY-MM-DD HH:mm:ss")).toDate(), + 2)}
                            timeIntervals={30}
                            filterDate={isWeekday}
                            placeholderText={t("DATE")}
                            filterTime={filterPassedTime}
                            dateFormat="MM/dd/yyyy h:mm aa"
                            timeFormat='HH:mm'
                            disabledKeyboardNavigation
                            onKeyDown={(e) => {
                                e.preventDefault();
                            }}
                        />}
                    </div>
                    <button disabled={submitingForm} className={`font-family-proxima popup-form-button ${submitingForm && 'disable-submit-form'}`} onClick={handleSubmit}>{t("SCHEDULE_CALL")}</button>
                    <span className='font-family-roboto terms'>{t("PERSONAL_DATA")} <a href={legfile}>{t("HERE")}</a></span>
                </div> :
                ""
            }
        </>
    )
}


export default withTranslation()(PopUpForm);