import react, { useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import ClientPage from '../../Pages/ClientPage/ClientPage';
import { withTranslation } from 'react-i18next';
import LogoCarrilha from '../../../src/Assets/Logos_Clientes/carrilha-de-palma.svg';
import LogoCasaPedreira from '../../../src/Assets/Logos_Clientes/casa-da-pedreira.svg';
import LogoCasaFranco from '../../../src/Assets/Logos_Clientes/casa-franco-falcao.svg';
import LogoConqueiros from '../../../src/Assets/Logos_Clientes/conqueiros.png';
import LogoHerdadeGrous from '../../../src/Assets/Logos_Clientes/herdade-grous.png';
import LogoJose from '../../../src/Assets/Logos_Clientes/jose-maria-da-fonseca.png';
import LogoCholda from '../../../src/Assets/Logos_Clientes/quinta-da-cholda.png';
import LogoTorreFigueiras from '../../../src/Assets/Logos_Clientes/torre-das-figueiras.png';

const apiBase = process.env.REACT_APP_API_BASE_URL;

// const badges = [{
//     orgID: 493,
//     content: {
//         logoImg: LogoTorreFigueiras,
//         logoText: "TORRE_FIGUEIRAS_TEXT",
//         measured: true,
//         commited: false,
//         compliant: false, 
//         resilient: false
//     }},
//     {orgID: 499,
//     content: {
//         logoImg: LogoCholda,
//         logoText: "CHOLDA_TEXT",
//         measured: true,
//         commited: false,
//         compliant: false, 
//         resilient: false
//     }},
//     {orgID: 48,
//     content: {
//         logoImg: LogoJose,
//         logoText: "JOSE_TEXT",
//         measured: true,
//         commited: false,
//         compliant: false, 
//         resilient: false
//     }},
//     {orgID: 418,
//     content: {
//         logoImg: LogoHerdadeGrous,
//         logoText: "HERDADE_GROUS_TEXT",
//         measured: true,
//         commited: false,
//         compliant: false, 
//         resilient: false
//     }},
//     {orgID: 21,
//     content: {
//         logoImg: LogoConqueiros,
//         logoText: "CONQUEIROS_TEXT",
//         measured: true,
//         commited: false,
//         compliant: false, 
//         resilient: false
//     }},
//     {orgID: 496,
//     content: {
//         logoImg: LogoCasaFranco,
//         logoText: "CASA_FRANCO_TEXT",
//         measured: true,
//         commited: false,
//         compliant: false, 
//         resilient: false
//     }},
//     {orgID: 495,
//     content: {
//         logoImg: LogoCasaPedreira,
//         logoText: "PEDREIRA_TEXT",
//         measured: true,
//         commited: false,
//         compliant: false, 
//         resilient: false
//     }},
//     {orgID: 494,
//     content: {
//         logoImg: LogoCarrilha,
//         logoText: "CARRILHA_TEXT",
//         measured: true,
//         commited: false,
//         compliant: false, 
//         resilient: false
//     }}
// ]

const ClientBadges = (props) => {
    const {t} = props;
    const {orgID} =  useParams();
    const [showBadges, setShowBadges] = useState(false);
    const [badgeContent, setBadgeContent] = useState(null);
    const [name, setName] = useState();
    const [description, setDescription] = useState();
    const [logoUrl, setLogoUrl] = useState();

    useEffect(() => {
        const getDescriptionAndLogo = async () => {
            const response = await fetch(`${apiBase}organization/description/${orgID}`);
            const {description, url} = await response.json();
            setDescription(description);
            setLogoUrl(url);
        }
        const getHasPublicBadges = async () => {
            const response = await fetch(`${apiBase}/badges/${orgID}`);
            const { hasPublicBadge, name, badges } = await response.json();
            setShowBadges(hasPublicBadge === 1 ? true : false);
            setBadgeContent(badges ? badges : {});
            setName(name);
            // const index = badges.findIndex(badge => badge.orgID === parseInt(orgID));
            // index >= 0 ? setBadgeContent(badges) : setBadgeContent({});
        }
        getHasPublicBadges();
        getDescriptionAndLogo();
    }, [])

    const renderClient = () => {
        if(badgeContent) {
            return showBadges ?
             (<ClientPage
                title={name}
                logoImg = {logoUrl}
                logoText={description}
                measured={badgeContent.measured}
                commited={badgeContent.commited}
                compliant={badgeContent.compliant}
                resilient={badgeContent.resilient}/> )
            : (<Navigate to="/page-not-found" />)
        }
    }

    return(
        <>
        {   
            renderClient()
        }
        </> 
    )
}


export default withTranslation()(ClientBadges);