import './App.css';
import {withTranslation} from 'react-i18next';
import Home from './Pages/Home/Home';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Navbar from './Components/Navbar/Navbar';
import About from './Pages/About/About';
import OurClients from './Pages/OurClients/OurClients';
import Contact from './Pages/Contact/Contact';
import CarbonNeutrality from './Pages/CarbonNeutrality/CarbonNeutrality';
import PageNotFound from './Pages/NotFound/PageNotFound';
import ClientBadges from './Components/ClientBadges/ClientBadges';
import PopUpCircle from './Components/ContactPopup/PopUpCircle';
import { useSelector, useDispatch } from 'react-redux';

function App( props ) {
  const {t} = props;
  const { contactPopupOpen } = useSelector((state) => state.contactPopup);
  return (
    <div className="App">
      <Router>
        <Navbar t={t}/>
        <div className={contactPopupOpen ? `pages` : ''}>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/our-pioneers" element={<OurClients />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/our-services" element={<CarbonNeutrality />} />
            <Route path="/badges/:orgID" element={<ClientBadges />} />
            {/* <Route exact path="/our-pioneers/torre-figueiras" element={<ClientPage logoImg={LogoTorreFigueiras} logoText={t("TORRE_FIGUEIRAS_TEXT")}  measured={true} commited={false} compliant={false} resilient={false}/>} />
            <Route exact path="/our-pioneers/quinta-cholda" element={<ClientPage logoImg={LogoCholda} logoText={t("CHOLDA_TEXT")}  measured={true} commited={false} compliant={false} resilient={false}/>} />
            <Route exact path="/our-pioneers/jose-maria-fonseca" element={<ClientPage logoImg={LogoJose} logoText={t("JOSE_TEXT")}  measured={true} commited={false} compliant={false} resilient={false}/>} />
            <Route exact path="/our-pioneers/herdade-grous" element={<ClientPage logoImg={LogoHerdadeGrous} logoText={t("HERDADE_GROUS_TEXT")}  measured={true} commited={false} compliant={false} resilient={false}/>} />
            <Route exact path="/our-pioneers/conqueiros" element={<ClientPage logoImg={LogoConqueiros} logoText={t("CONQUEIROS_TEXT")}  measured={true} commited={false} compliant={false} resilient={false}/>} />
            <Route exact path="/our-pioneers/casa-franco-falcao" element={<ClientPage logoImg={LogoCasaFranco} logoText={t("CASA_FRANCO_TEXT")}  measured={true} commited={false} compliant={false} resilient={false}/>} />
            <Route exact path="/our-pioneers/casa-pedreira" element={<ClientPage logoImg={LogoCasaPedreira} logoText={t("PEDREIRA_TEXT")}  measured={true} commited={false} compliant={false} resilient={false}/>} />
            <Route exact path="/our-pioneers/carrilha-palma" element={<ClientPage logoImg={LogoCarrilha} logoText={t("CARRILHA_TEXT")}  measured={true} commited={false} compliant={false} resilient={false}/>} /> */}
            <Route path="/page-not-found" element={<PageNotFound />} />
            <Route path="*" element={<Navigate to="/page-not-found" />} />
          </Routes>
        </div>
      </Router>
    </div>
  );
}

export default withTranslation()(App);
